import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSocketContext } from '../context/SocketContext';

// Create a context for the user
const UserContext = createContext();

// Create a provider component
export function UserProvider({ children }) {
  const socket = useSocketContext();
  const [user, setUser] = useState({
    id: socket.id,
    userName: '',
    countryScore: 0,
    ratingScore: 0,
    pricingScore: 0,
  });

  // Function to update userName
  const updateUserName = (newUserName) => {
    setUser((prevUser) => ({
      ...prevUser,
      userName: newUserName,
      id : socket.id
    }));
    socket.emit('newUser', user);
  };

  // Function to update countryScore
  const updateCountryScore = (newCountryScore) => {
    setUser((prevUser) => ({
      ...prevUser,
      countryScore: prevUser.countryScore + newCountryScore,
    }));
  };

  // Function to update ratingScore
  const updateRatingScore = (newRatingScore) => {
    setUser((prevUser) => ({
      ...prevUser,
      ratingScore: prevUser.ratingScore + newRatingScore,
    }));
  };

  // Function to update pricingScore
  const updatePricingScore = (newPricingScore) => {
    setUser((prevUser) => ({
      ...prevUser,
      pricingScore: prevUser.pricingScore + newPricingScore,
    }));
  };

  // Emit updates to the server whenever the user state changes
  useEffect(() => {
    if (user.userName) {
      socket.emit('ScoreUpdate', user);
    }
  }, [user, socket]);

  // Provide the user state and update functions
  return (
    <UserContext.Provider
      value={{
        user,
        updateUserName,
        updateCountryScore,
        updateRatingScore,
        updatePricingScore,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

// Custom hook to use the UserContext
export const useUserContext = () => useContext(UserContext);
