import React, { useState } from 'react';
import CountryGuess from './CountryGuess';
import PriceGuess from './PriceGuess';
import RatingGuess from './RatingGuess';
import Welcome from './Welcome';
import { useUserContext } from '../context/UserContext';

const Quiz = ({ wines }) => {
    const [step, setStep] = useState(0);
    const [wineIndex, setWineIndex] = useState(0);
    const { user } = useUserContext();
    const username = user.userName;
    const nextStep = () => {
        if (step < 2) {
            setStep(step + 1);
        } else {
            setStep(0);
            setWineIndex(wineIndex + 1);
        }
    };

    if (wineIndex >= wines.length) {
        return <div className="text-xl text-center mt-10">Quiz Completed!</div>;
    }

    const currentWine = wines[wineIndex];

    return (
        <div className="w-2/4  h-3/5 mx-auto bg-[#f07167] p-6 rounded-lg shadow-2xl shadow-[#47020c] place-content-center mb-4">
            {username === '' && <Welcome />}
            {username !== '' && (
                <>
                    <h2 className="text-2xl font-bold text-center mb-4 text-[#fdf0d5]">Wine {wineIndex + 1} / {wines.length}</h2>
                    <p className="text-lg text-center mb-4 text-[#fdf0d5]" >{currentWine.grape}</p>
                    <p className="text-lg text-center mb-4 text-[#fdf0d5]">{currentWine.description}</p>
                    {/* {step === 0 && <AnswerScreen answer={true} points={5} onNext={nextStep} />} */}
                    {step === 0 && <CountryGuess wine={currentWine} onNext={nextStep} />}
                    {step === 1 && <PriceGuess wine={currentWine} onNext={nextStep} />}
                    {step === 2 && <RatingGuess wine={currentWine} onNext={nextStep} />}
                </>
            )}
        </div>
    );
    
};

export default Quiz;
