// context/SocketContext.js
import React, { createContext, useContext } from 'react';
import useSocket from '../hooks/useSocket';
const { REACT_APP_ENV } = process.env;

const BACKEND_URL = REACT_APP_ENV !== 'development' ? 'https://api.wineguessr.dk': "http://localhost:8080";
const SocketContext = createContext(null);

export const useSocketContext = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const socket = useSocket(BACKEND_URL);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
