// MainApp.js
import React from 'react';
import { SocketProvider } from './context/SocketContext';
import App from './App';

function MainApp() {
    return (
        <SocketProvider>
            <App />
        </SocketProvider>
    );
}

export default MainApp;
