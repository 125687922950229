import React, {useState, useEffect } from 'react';
import { useSocketContext } from '../context/SocketContext';

function ScoreBoard() {
  const socket = useSocketContext();
  const [userPoints, setUserPoints] = useState([]);

  useEffect(() => {
    const handleUpdatingPoints = (data) => {
      setUserPoints(data);
    };

    socket.on('updatedPoints', handleUpdatingPoints);

  
  }, [socket]);

  return (
    <div className={`fixed left-0 bottom-0 w-3/5 h-48 bg-[#fdf0d5]  p-4 flex flex-row`}>
      <div>
        <h2 className="text-l font-bold mb-2 mr-10">Country Scores</h2>
        <ul className="list-none">
            {userPoints
             .filter(user => user.userName !== "" && user.id)
             .map((user) => (
               <li key={user.userName} className="mb-1">
                {user.userName} : {user.countryScore}
             </li>
          ))}
        </ul>
      </div>
      <div>
        <h2 className="text-l font-bold mb-2">Rating Scores</h2>
          <ul className="list-none">
            {userPoints
             .filter(user => user.userName !== "" && user.id)
             .map((user) => (
               <li key={user.userName} className="mb-1">
                {user.userName} : {user.ratingScore * user.pricingScore}
             </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ScoreBoard;
