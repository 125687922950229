import React, { useState, useEffect } from 'react';
import { useSocketContext } from '../context/SocketContext';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const socket = useSocketContext();

  useEffect(() => {
    const handleNewUserResponse = (data) => {
      setUsers(data);
    };

    socket.on('newUserResponse', handleNewUserResponse);

    return () => {
      socket.off('newUserResponse', handleNewUserResponse);
    };
  }, [socket]);

  return (
    <div className="w-2/5 h-48 fixed right-0 bottom-0 bg-[#fdf0d5] p-4">
      <div>
        <h4 className="text-lg font-semibold mb-2">ACTIVE USERS</h4>
        <div className="space-y-2">
          {users.map((user) => (
            <p key={user.id}>{user.userName}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserList;
