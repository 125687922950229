// hooks/useSocket.js
import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const useSocket = (url) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = io(url);
        setSocket(newSocket);

        newSocket.on('connect', () => {
            console.log(`Connected to server: ${newSocket.id}`);
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    return socket;
};

export default useSocket;
