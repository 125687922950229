import React, { useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import FlagSelection from './FlagSelection';

const CountryGuess = ({ wine, onNext }) => {
    const [guess, setGuess] = useState(null); // Changed to null to work with react-select
    const [round, setRound] = useState(1);
    const options = countryList().getData();

    const handleSubmit = (e) => {
        e.preventDefault();
        setRound(2); // Move to the flag selection round
    };

    const handleChange = (selectedOption) => {
        setGuess(selectedOption);
    };

    if (round === 2) {
        return <FlagSelection wine={wine} guess={guess} onNext={onNext} />;
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4 flex flex-col gap-2 items-center">
            <h2 className="text-2xl font-bold text-center mt-4 text-[#fdf0d5]">Guess the Country</h2>
            <Select
                options={options}
                value={guess}
                onChange={handleChange}
                className="w-2/4"
                placeholder="Enter country"
            />
            <button type="submit" className="primary-button" >
                Submit
            </button>
        </form>
    );
};

export default CountryGuess;
