import React, { useState } from 'react';
import { useUserContext } from '../context/UserContext';
import { useSocketContext } from '../context/SocketContext';


const WelcomeScreen = () => {
  const [usernameState, setUsernameState] = useState('');
  const{ updateUserName } = useUserContext();
  const socket = useSocketContext();

  const handleInputChange = (event) => {
    setUsernameState(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (usernameState.trim()) {
      updateUserName(usernameState);
      socket.emit('newUser', { userName: usernameState, socketID: socket.id });
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold text-center text-[#fdf0d5] mb-6">Welcome to the WineGuessr</h2>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <input
          type="text"
          value={usernameState}
          onChange={handleInputChange}
          placeholder="Enter your username"
          className="p-3 text-lg border border-gray-300 rounded-lg mb-4 w-2/4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="primary-button"
        >
          Start Drinkin'
        </button>
      </form>
    </div>
  );
};

export default WelcomeScreen;
