// App.js
import React, { useEffect, useState } from 'react';
import Quiz from './components/Quiz';
import ScoreBoard from './components/ScoreBoard';
import UserList from './components/UserList';
import { UserProvider } from './context/UserContext';
import { useSocketContext } from './context/SocketContext';

function App() {
    const [wines, setWines] = useState([]);
    const [loading, setLoading] = useState(true);
    const socket = useSocketContext();

    useEffect(() => {
        fetch('/wines.json')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setWines(data);
                setLoading(false);
                if (socket) {
                    socket.emit('wine-data', true);
                }
            })
            .catch((error) => {
                console.error('Error fetching wines:', error);
                setLoading(false);
            });
    }, [socket]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen text-xl">Loading...</div>;
    }

    return (
        <UserProvider>
            <ScoreBoard />
            <div className="h-screen p-4 bg-[url('/public/background2.jpeg')] flex justify-center pt-32">
                <Quiz wines={wines} />
            </div>
            <UserList />
        </UserProvider>
    );
}

export default App;
