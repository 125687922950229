const AnswerScreen = ({ answer, points, type, onNext }) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        onNext();
    };

    return (
        <>
            {type === "country" ? (
                <form onSubmit={handleSubmit} className={`space-y-4 `}>
                    <h2 className="text-2xl font-bold text-center text-[#fdf0d5]">{answer ? "You got the answer right!" : "You got the answer wrong"}</h2>
                    <p className='text-center text-xl  text-[#fdf0d5]'>Points: {points}</p>
                    <button type="submit" className="w-full bg-[#fdf0d5] text-[#f25c54] py-2 rounded hover:bg-[#f07167] hover:text-white hover:outline-none hover:ring-2 hover:ring-white">
                        Continue
                    </button>
                </form>
            ) : (
                <form onSubmit={handleSubmit} className={`space-y-4`}>
                    <h2 className='text-center text-xl text-[#fdf0d5]'>
                        {type === "price" ? `You were ${points},- from the right answer!` : `You were ${points} from the right rating!`}
                    </h2>
                    <button type="submit" className="w-full bg-[#fdf0d5] text-[#f25c54] py-2 rounded hover:bg-[#f07167] hover:text-white hover:outline-none hover:ring-2 hover:ring-white">
                        Continue
                    </button>
                </form>
            )}
        </>
    );
};

export default AnswerScreen;
