import React, { useState } from 'react';
import { useUserContext } from '../context/UserContext';
import AnswerScreen from './AnswerScreen';


const RatingGuess = ({ wine, onNext }) => {
    const [guess, setGuess] = useState('');
    const { updateRatingScore } = useUserContext();
    const [showAnswerScreen, setShowAnswerScreen] = useState(false);
    const [answerProps, setAnswerProps] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        let answer = false;
        if (wine && wine.rating !== undefined) {
            const ratingGuess = parseInt(guess, 10);
            const ratingDiff = Math.abs(ratingGuess - wine.rating);
            updateRatingScore(ratingDiff);
            setAnswerProps({ answer, points: ratingDiff, type: 'rating', onNext });
            setShowAnswerScreen(true);
        } else {
            console.error('Wine data is not available');
            alert('Error: Wine data is not available');
        }
    };

    if (showAnswerScreen) {
        return <AnswerScreen {...answerProps} />;
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4 flex flex-col gap-2 items-center">
            <h2 className="text-2xl font-bold text-[#fdf0d5]">Guess the Rating</h2>
            <input
                type="number"
                value={guess}
                onChange={(e) => setGuess(e.target.value)}
                className="w-2/4 p-2 border border-gray-300 rounded"
                placeholder="Enter rating"
            />
            <button type="submit" className="primary-button">
                Submit
            </button>
        </form>
    );
};

export default RatingGuess;
