import React, { useState } from 'react';
import { useUserContext } from '../context/UserContext';
import AnswerScreen from './AnswerScreen';


const PriceGuess = ({ wine, onNext }) => {
    const [guess, setGuess] = useState('');
    const { updatePricingScore } = useUserContext();
    const [showAnswerScreen, setShowAnswerScreen] = useState(false);
    const [answerProps, setAnswerProps] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        let answer = false;

        if (wine && wine.price !== undefined) {
            const priceGuess = parseFloat(guess);
            const priceDiff = Math.abs(priceGuess - wine.price);
            updatePricingScore(priceDiff);
            setAnswerProps({ answer, points: priceDiff, type: 'price', onNext });
            setShowAnswerScreen(true);
        } else {
            console.error('Wine data is not available');
            alert('Error: Wine data is not available');
        }
    };

    if (showAnswerScreen) {
        return <AnswerScreen {...answerProps} />;
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4 flex flex-col gap-2 items-center">
            <h2 className="text-2xl font-bold text-[#fdf0d5]">Guess the Price</h2>
            <input
                type="number"
                value={guess}
                onChange={(e) => setGuess(e.target.value)}
                className="w-2/4 p-2 border border-gray-300 rounded"
                placeholder="Enter price"
            />
            <button type="submit" className="primary-button">
                Submit
            </button>
        </form>
    );
};

export default PriceGuess;
