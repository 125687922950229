import React, { useState } from 'react';
import { useUserContext } from '../context/UserContext';
import AnswerScreen from './AnswerScreen';

const FlagSelection = ({ wine, guess, onNext }) => {
    const { updateCountryScore } = useUserContext(); // Access scores and setScores from context
    const [showAnswerScreen, setShowAnswerScreen] = useState(false);
    const [answerProps, setAnswerProps] = useState({});

    const handleSelection = (selectedCountry) => {
        let points = 0;
        let answer = false;
        let type = 'country';

        if (selectedCountry.toLowerCase() === wine.country.toLowerCase()) {
            if (guess.label.toLowerCase() === wine.country.toLowerCase()) {
                points = 5;
            } else {
                points = 3;
            }
            answer = true;
            updateCountryScore(points);
            setAnswerProps({ answer, points, type, onNext });
            setShowAnswerScreen(true);
        } else {
            answer = false;
            updateCountryScore(points);
            setAnswerProps({ answer, points, type, onNext });
            setShowAnswerScreen(true);
        }
    };

    if (showAnswerScreen) {
        return <AnswerScreen {...answerProps} />;
    }

    return (
        <div>
            <h2 className="text-2xl font-bold text-center ">Pick the Flag</h2>
            <div className="flex justify-around mt-4">
                {Object.entries(wine.flags).map(([country, flagPath], index) => (
                    <img
                        key={index}
                        src={flagPath}
                        alt={`${country} Flag`}
                        className="w-24 h-16 cursor-pointer border-2 border-gray-300 hover:border-blue-500"
                        onClick={() => handleSelection(country)}
                    />
                ))}
            </div>
        </div>
    );
};

export default FlagSelection;
